import React, { useState, useEffect, useContext } from "react";
import { RouteComponentProps, useParams } from "react-router-dom";
import {
	FiDollarSign,
	FiUser,
	FiPhone,
} from "react-icons/fi";
import { RiMapPin2Fill } from "react-icons/ri";

import Row from "../components/Row";
import Alert from "../components/Alert";
import Input from "../components/Input";
import Loading from "../components/Loading";

import AppContext, { AppContextType } from "../contexts/AppContext";
import OrderContext, { OrderContextType } from "../contexts/OrderContext";
import Geocode from "react-geocode";

import { Cart, Combo, Order } from "../utils/types";
import {
	formatValue,
	phoneMask,
	checkOrder,
	createCartArray,
	createComboArray,
	justNumbers,
	decryptText,
	calculeTotal,
} from "../utils/functions";

import "./styles.css";
import HeaderDefault from "../components/HeaderDefault";
import ListHeader from "../components/ListHeader";
import ModalPedidoEnviado from "../components/ModalPedidoEnviado";
import ScannerQrCode from "../components/ScannerQrCode";
import GoogleMaps from "../components/GoogleMaps";

import ModalAddress from "../components/ModalAddress";
import CartAddress from "../components/CartAddress";
import CartInfoDelivery from "../components/CartInfoDelivery";
import PaymentBrick from "../components/PaymentBrick";
import server from "../services/newServer";
import PaymentStatus from "../components/PaymentStatus";

// eslint-disable-next-line no-useless-escape
const phoneRegex = /^[\+]?[(]?[0-9]{2}[)]?[-\s\.]?[0-9]{5}[-\s\.]?[0-9]{4,6}$/;

const FinishCart: React.FC<RouteComponentProps> = ({ history }) => {
	/* @ts-ignore */
	const { header } = useParams();

	const {
		info,
		indexHorario,
		cities,
		neighborhoods,
		client,
		ficha,
		globalError,
		getInfo,
		getCities,
		getPayments,
		timeDelivery,
		timeNoDelivery,
		calcTimeDelivery,
		getClientInfo,
		clearAppContextNotLocalStorage,
		clearSessionStorage,
		createGlobalError,
		getNeighborhoods,
		verifyDiscountCouponUnique,
		createNeighborhood,
		createTrackingLog,
	} = useContext<AppContextType>(AppContext);
	const {
		table,
		setTable,
		address,
		addressNumber,
		complement,
		city,
		neighborhood,
		taxa,
		idEntrega,
		position,
		getPrepTime,
		handleGetDelivery,
		handleChangeAddress,
		handleChangeAddressComplement,
		handleChangeAddressNumber,
		handleChangeNeighborhood,
		handleChangeCity,
		handleChangeTaxa,
		handleChangeidEntrega,
		orderContextError,
		create,
		createOrderContextError,
		clearOrderContextError,
		setDeliveryTime,
		coupon,
		cardNumber,
		cardCode,
		cardExpiration,
		cardCpf,
		cardHolderName,
		paymentMethodId,
		addCoupon,
		clearCardInfo,
	} = useContext<OrderContextType>(OrderContext);

	/* Booleanos */
	const [showLoading, setShowLoading] = useState<boolean>(false);
	const [showSucessAlert, setShowSuccessAlert] = useState<boolean>(false);

	/* Valores */
	const [subtotal, setSubtotal] = useState<number>(0);
	const [compTotal, setCompTotal] = useState<number>(0);

	//* Lista de pagamentos */
	const [paymentList, setPaymentList] = useState([]);
	/* Informações do cliente */
	const [name, setName] = useState<string>(client?.nome || ""); /* Nome */
	const [phone, setPhone] = useState<string>(
		client?.celular || ""
	); /* Numero de telefone */
	const [deliveryType, setDeliveryType] =
		useState<string>(""); /* Tipo do pedido */
	const [change, setChange] = useState<string>(""); /* Troco */
	/* const [idEntrega, setIdEntrega] = useState<number>(
		bairro?.id_entrega || 0
	); /* ID da entrega */
	// const [taxa, setTaxa] = useState<number>(bairro?.valor || 0);
	// const [taxa, setTaxa] = useState<number>(0);
	const [payment, setPayment] =
		useState<string>("SELECIONAR"); /* Forma de pagamento */
	// const [focused, setFocused] = useState<boolean>(false);

	const [isRestaurantClose, setIsRestaurantClose] = useState<boolean>(false);
	const [openScannerQrCode, setOpenScannerQrCode] = useState<boolean>(false);
	const [showInformacoes, setShowInformacoes] = useState<boolean>(false);
	const [isComanda, setIsComanda] = useState<boolean>(false);
	const [message, setMessage] = useState<string>("false");
	const [openModalPedidoEnvido, setOpenModalPedidoEnvido] =
		useState<boolean>(false);
	const [openGoogleMaps, setOpenGoogleMaps] = useState<boolean>(false);
	const [openModalAddAddress, setOpenModalAddAddress] = useState<any>({
		open: false,
		withAdress: false,
	});

	const [onlinePayID, setonlinePayID] = useState<any>('');
	const [sendClicked, setSendClicked] = useState(false);
	const [uniqueCouponWarning, setUniqueCouponWarning] = useState('');

	const handleAction = async (pago?: boolean) => {
		try {
			if (pago) {
				const trackingParams = {
					tipo: 'INF',
					descricao: `${name} - realizou pagamento com a forma de pagamento: ${payment} | Valor: R$ ${subtotal + taxa}`,
					id_processo: null,
					id_tabela: null,
					tabela: "pdv_cad_venda",
					login_usuario: "voamenu",
					cod_usuario: null,
					nome_computador: "voamenu",
					versao: null,
					data: "",
					sql: "",
					tipo_log: "T"
				}
				createTrackingLog(header, trackingParams)
			}
			if (!ficha && (payment === "" || payment === "SELECIONAR")) {
				setMessage("Selecione a forma de pagamento!");
				setIsComanda(true);
				return;
			}
			setShowLoading(true);
			const response = await getInfo(header);
			const haveBarcode = sessionStorage.getItem("@CHEF:barcode");
			const localFicha = {
				type: ficha ? ficha.type : null,
				number: ficha ? ficha.number : null,
				mesa_vinculada: table === "" ? 0 : Number(table)
			}
			if (haveBarcode) {
				const barcode = JSON.parse(haveBarcode);
				localFicha.type = barcode.type;
				localFicha.number = barcode.number;
				localFicha.mesa_vinculada = barcode.mesa_vinculada;
			}
			if (response.deliveryDate[indexHorario].aberto) {
				if (response.disponivel) {
					if (!ficha || table !== "" || ficha.type === "Mesa") {
						if (info.smart_identificar_cliente === 1) {
							if (name === "" && phone === "") {
								setMessage("Preencha todos os campos!");
								setIsComanda(true);
								return;
							}
						}
						let localCart = [];
						let localCombos = [];

						let sessionCart = JSON.parse(decryptText(sessionStorage.getItem("@CHEF:cart")));
						let sessionCombos = JSON.parse(
							decryptText(sessionStorage.getItem("@CHEF:combos"))
						);
						if (sessionCart) {
							localCart = await createCartArray([...sessionCart]);
						} else {
							sessionCart = [];
						}
						if (sessionCombos) {
							localCombos = await createComboArray([...sessionCombos]);
						} else {
							sessionCombos = [];
						}
						const localTotal =
							deliveryType === "ENTREGA" ? subtotal + taxa : subtotal;

						let localNeighborhood = null;
						let localCity = null;

						if (!address && deliveryType === "ENTREGA") {
							setMessage("O endereço não pode estar vazio!");
							setShowLoading(false);
							setIsComanda(true);
							return;
						}

						if (city && deliveryType === "ENTREGA") {
							const founded = cities.find(
								(item) =>
									item?.nome.toLocaleUpperCase() === city?.toLocaleUpperCase()
							);
							localCity = founded ? founded.id_municipio : 0;
						}
						if (neighborhood && deliveryType === "ENTREGA") {
							const founded = neighborhoods.find(
								(item) =>
									item?.nome.toLocaleUpperCase() === neighborhood?.toLocaleUpperCase()
							);
							if (founded) {
								localNeighborhood = founded.id_bairro;
							} else {
								const createBairro = await createNeighborhood(header, neighborhood.toLocaleUpperCase(), localCity, idEntrega);
								localNeighborhood = createBairro.data.id_bairro;
							}
						}
						let date = new Date();
						let id_mercado_pago = null;
						let params: Order = {
							obs: "",
							payment,
							subtotal,
							comp_total: compTotal,
							total: localTotal,
							desconto: coupon?.value > 0 ? coupon.value : 0,
							id_cupom_desconto: coupon?.id ? coupon.id : null,
							quant: sessionCart.length + sessionCombos?.length,
							type: localFicha.type,
							number: localFicha.number,
							taxa,
							change: change === "" ? 0 : parseFloat(change),
							deliveryType,
							saveData: deliveryType === "ENTREGA" ? true : false,
							user: {
								name: name?.replace(/'/gi, ""),
								phone: phone,
								//whatsapp,
								address: deliveryType === "ENTREGA" ? address : "",
								addressNumber: deliveryType === "ENTREGA" ? addressNumber : "",
								id_entrega: deliveryType === "ENTREGA" ? idEntrega : 0,
								id_neighborhood: deliveryType === "ENTREGA" ? (localNeighborhood?.toString() ? localNeighborhood?.toString() : "2") : "none",
								id_city: deliveryType === "ENTREGA" ? (localCity?.toString() ? localCity?.toString() : "1") : "none",
								complement: deliveryType === "ENTREGA" ? complement : "" /* @ts-ignore */,
								neighborhood: deliveryType === "ENTREGA" ? neighborhood : null /* @ts-ignore */,
								city: deliveryType === "ENTREGA" ? city : null,
							},
							itens: localCart,
							combos: localCombos,
							timeNow: `${date.getHours()}:${date.getMinutes()}`,
							dateNow: date.toLocaleDateString(),
							header,
							time: null,
							mesa_vinculada: localFicha.mesa_vinculada,
							hash_venda: null,
							id_mercado_pago: null
						};
						await checkOrder(params);

						if ((payment === 'PIX ONLINE' || payment === 'CREDITO ONLINE' || payment === 'DEBITO ONLINE') && !pago) {
							id_mercado_pago = await handleOnlinePayment();
							if (!id_mercado_pago) {
								setShowLoading(false);
								return
							}
						}

						params.id_mercado_pago = id_mercado_pago;

						let resp: any = false;

						if (!((payment === 'PIX ONLINE' || payment === 'CREDITO ONLINE' || payment === 'DEBITO ONLINE') && pago)) {
							resp = await create(
								header,
								params,
								info.tempo_minimo_preparo
							);
						} else if ((payment === 'PIX ONLINE' || payment === 'CREDITO ONLINE' || payment === 'DEBITO ONLINE') && pago) {
							clearSessionStorage();
							clearCardInfo();
							if (!ficha)
								history.push(`/${header}/success`);

							setShowLoading(false);
						}

						if (resp) {
							/* @ts-ignore */
							params.id_pdv = resp.id;
							params.entrega_prevista = resp.entrega_prevista;

							if (ficha) {

								//Verifica se já há uma ordem aberda para a comanda ou mesa e adiciona os itens se já houver
								var localOrder: any = localStorage.getItem('@CHEF:order');

								if (localOrder) {

									//ITENS
									localOrder = JSON.parse(localOrder);
									const localItens: Order = localOrder;
									localItens.itens.forEach(el => {
										params.itens.push(el);
									});

									const comboLocal: Order = localOrder;
									comboLocal.combos.forEach(combo => {
										params.combos.push(combo);
									});

									//Calcula o valor total a ser mostrado.
									var totalItens = 0;
									params.itens.forEach(item => {
										totalItens += item.valor;
									});

									params.combos.forEach(combo => {
										totalItens += combo.valor;
									})

									params.total = totalItens;
								}

								// Adiciona a hora da criação
								const finalParams: any = { ...params, createdAt: new Date().getTime() }

								localStorage.setItem("@CHEF:order", JSON.stringify(finalParams));
							} else {
								const finalParams: any = { ...params, createdAt: new Date().getTime() }
								localStorage.setItem("@CHEF:order", JSON.stringify(finalParams));
							}

							if ((payment === 'PIX ONLINE' || payment === 'CREDITO ONLINE' || payment === 'DEBITO ONLINE') && !pago) {
								setShowLoading(false);
								return
							}

							clearSessionStorage();
							clearCardInfo();
							if (!ficha) {
								history.push(`/${header}/success`);
							} else {
								setOpenModalPedidoEnvido(true);
							}
						} else if (!((payment === 'PIX ONLINE' || payment === 'CREDITO ONLINE' || payment === 'DEBITO ONLINE') && pago)) {
							createOrderContextError(
								"Não foi possivel realizar o pedido! Tente novamente"
							);
						}
						setShowLoading(false);
					} else {
						setShowLoading(false);
						setMessage("Preencha todos os campos!");
						setIsComanda(true);
					}
				} else {
					createGlobalError(
						`No momento não estamos conseguindo atender novos pedidos. Tente novamente mais tarde.`
					);
				}
			} else {
				setIsRestaurantClose(true);
			}
		} catch (err) {
			setShowLoading(false);
			createOrderContextError(err);
		}
	};

	const handleOnlinePayment = async () => {
		setSendClicked(true);
		const desconto = coupon?.value > 0 ? coupon.value : 0
		let paymentMethod = ''
		switch (payment) {
			case 'PIX ONLINE':
				paymentMethod = 'pix'
				break;
			case 'CREDITO ONLINE':
				paymentMethod = paymentMethodId
				break;
			case 'DEBITO ONLINE':
				paymentMethod = paymentMethodId
				break;
			default:
				break;
		}

		if (paymentMethod === '') {
			setMessage("Cartão invalido, verifique as informações do cartão.");
			setIsComanda(true);
			setSendClicked(false);
			return
		}

		if (paymentMethod !== 'pix' && (cardNumber === '' || cardCode === '' || cardExpiration === '' || cardHolderName === '' || cardCpf === '')) {
			setMessage("Preencha todos os campos do metodo de pagamento!");
			setIsComanda(true);
			setSendClicked(false);
			return
		}

		let respCard = null;

		if (paymentMethod !== 'pix') {
			const [cardMonth, cardYear] = cardExpiration.split('/');
			respCard = await server.post('/process_payment/cardinfo', {
				card_number: cardNumber,
				expiration_month: cardMonth,
				expiration_year: 20 + cardYear,
				security_code: cardCode,
				access_token: info.key_mercadopago,
				holder_name: cardHolderName,
				holder_cpf: cardCpf
			});
		}

		const params = {
			token: respCard?.data?.id ? respCard?.data?.id : '',
			accessToken: info.key_mercadopago,
			transaction_amount: (subtotal - desconto) + taxa,
			description: "Pagamento voamenu",
			payment_method_id: paymentMethod,
			installments: 1,
			payer: {
				email: info.email_mercadopago,
				identification: {
					number: cardCpf,
					type: "CPF"
				}
			}
		}
		const resp = await server.post('/process_payment', params);
		if (resp.data) {
			setonlinePayID(resp.data.body.id);
			return resp.data.body.id;
		} else {
			setSendClicked(false);
			setMessage("Erro ao tentar realizar pagamento!");
			setIsComanda(true);
			return false;
		}
	}

	const handleSuccess = () => {
		setShowSuccessAlert(false);
		history.push(`/${header}/success`);
	};

	const handlePhoneMask = (text: string) => {
		setTimeout(() => {
			const value = phoneMask(text);
			if (value !== text) text = value;
			setPhone(value);
		}, 1);
	};

	const handlePhoneInputBlurEvent = async () => {
		try {
			if (!phoneRegex.test(phone)) return;
			if (info.utiliza_cupom_desconto && info.permitir_unico_cupom && coupon) {
				const checkUniqueCoupon = await verifyDiscountCouponUnique(header, coupon.id, phone);
				if (checkUniqueCoupon) {
					setUniqueCouponWarning("Desconto não aplicado, o cupom já foi utilizado por este usuário!")
					addCoupon('');
				}
			}
			setShowLoading(true);
			const resp = await getClientInfo(header, phone);
			if (resp) {
				setName(resp.nome);
				if (deliveryType === "ENTREGA" && resp.id_municipio && resp.endereco) {
					await getNeighborhoods(header, resp.id_municipio);
					const foundedCities = cities?.find(
						(item) => item?.id_municipio === resp.id_municipio
					);
					const response = await checkLocation(resp.endereco);
					if (response) {
						handleChangeAddress(resp.endereco ? resp.endereco : "");
						handleChangeAddressNumber(
							resp.numero ? resp.numero.toString() : ""
						);
						handleChangeAddressComplement(
							resp.complemento ? resp.complemento : ""
						);
						handleChangeNeighborhood(resp.bairro ? resp.bairro : "");
						handleChangeCity(foundedCities?.nome ? foundedCities?.nome : "");
					}
				} else {
					handleChangeAddress("");
					handleChangeCity("none");
					handleChangeNeighborhood("none");
					handleChangeidEntrega(0);
					setDeliveryTime(0);
					handleChangeTaxa(0);
				}
			} else {
				handleChangeCity("none");
				handleChangeNeighborhood("none");
				handleChangeidEntrega(0);
				setDeliveryTime(0);
				handleChangeTaxa(0);
			}
			setShowInformacoes(true);
			setShowLoading(false);
		} catch (err) {
			setShowLoading(false);
			createGlobalError(err);
		}
	};

	const checkLocation = async (addressCheck: string) => {
		try {
			let local;
			await Geocode.fromAddress(addressCheck).then(
				(response) => {
					const { lat, lng } = response.results[0].geometry.location;
					local = {
						latitude: lat,
						longitude: lng,
					};
				},
				(error) => {
					console.error(error);
				}
			);
			const resp = await handleGetDelivery(header, local);
			if (resp) {
				return true;
			} else {
				return false;
			}
		} catch (err) {
			console.log(err);
		}
	};

	const handleChangeInputBlurEvent = () => {
		const formated = parseFloat(change);
		setChange(formated ? formated?.toFixed(2)?.toString() : "");
	};

	const handleSetMesa = (text: string) => {
		let formatedText = justNumbers(text);
		if (isNaN(formatedText)) {
			setTable("");
		} else {
			setTable(formatedText);
		}

	};

	const handleCalculateTotal = async () => {
		/* Variaveis de valores locais */
		let tempSubTotal = 0;
		let tempCompTotal = 0;

		/* Percorre o array e soma nas variaveis de valores */
		const sessionCart: Cart[] = JSON.parse(
			decryptText(sessionStorage.getItem("@CHEF:cart"))
		);
		if (sessionCart?.length > 0) {
			for (const iteratorCart of sessionCart) {
				tempSubTotal += calculeTotal(iteratorCart, iteratorCart.quant);
				tempCompTotal += iteratorCart.comp_total;
			}
		}

		/* Percorre o array e soma nas variaveis de valores */
		const sessionCombos: Combo[] = JSON.parse(
			decryptText(sessionStorage.getItem("@CHEF:combos"))
		);
		if (sessionCombos?.length > 0) {
			for (const iteratorCombos of sessionCombos) {
				tempSubTotal += iteratorCombos.quant * iteratorCombos.valor;
			}
		}
		/* Seta os states de valores */
		setSubtotal(tempSubTotal);
		setCompTotal(tempCompTotal);
		return tempSubTotal;
	};

	const findCitiesAndNeighborhoods = async () => {
		try {
			setShowLoading(true);
			const resp = await getCities(header);
			if (client && client.id_municipio) {
				await getNeighborhoods(header, client.id_municipio);
				const founded = neighborhoods.find(
					(item) => item.nome === neighborhood
				);
				setDeliveryTime(founded.tempo_entrega);
			} else {
				await getNeighborhoods(header, resp[0].id_municipio);
			}
			setShowLoading(false);
		} catch (err) {
			setShowLoading(false);
			createGlobalError(err);
		}
	};

	const handleGoBack = () => {
		history.push(`/${header}/Cart`);
	};

	const handleGoBackInformacoes = () => {
		setIsRestaurantClose(false);
		history.push(`/${header}/Informacoes`);
	};

	const handleFinishCard = async () => {
		setOpenModalPedidoEnvido(false);
		history.push(`/${header}`);
	};

	const handleClickDelivery = async (text: string) => {
		if (deliveryType === text) return;
		if (
			text === "ENTREGA" &&
			!ficha &&
			info.deliveryDate[indexHorario].entrega === 1 &&
			subtotal >= info.valor_minimo_pedido
		) {
			if (address !== "") {
				await handleGetDelivery(header, position);
			}
			setDeliveryType("ENTREGA");
		}
		if (text === "RETIRADA" && info.deliveryDate[indexHorario].retirada === 1) {
			setDeliveryType("RETIRADA");
		}
	};

	const handleCloseGoogleMaps = () => {
		setOpenGoogleMaps(false);
		setOpenModalAddAddress({
			open: true,
			withAdress: true,
		});
	};

	useEffect(() => {
		if (deliveryType === "RETIRADA") {
			setDeliveryTime(0);
			handleChangeTaxa(0);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [deliveryType, setDeliveryTime]);

	useEffect(() => {
		(async () => {
			try {
				setShowLoading(true);
				Geocode.setApiKey(info.geolocalizacao_key);
				Geocode.setLanguage("cs");
				Geocode.setRegion("BR");
				const tempSubTotal = await handleCalculateTotal();
				const localInfo = await getInfo(header);

				const payments = await getPayments(header);
				const paymentArray = []
				if (payment) {
					payments.forEach((item) => {
						paymentArray.push(item);
					})

					setPaymentList(paymentArray);
				}

				if (
					localInfo.deliveryDate[indexHorario].entrega === 1 &&
					!ficha &&
					tempSubTotal >= localInfo.valor_minimo_pedido
				) {
					setDeliveryType("ENTREGA");
					//if (timeNoDelivery) {
					const prepTime: number = getPrepTime();
					calcTimeDelivery(prepTime, false);
					//}
					await findCitiesAndNeighborhoods();
				} else {
					setDeliveryType("RETIRADA");
				}
				setShowLoading(false);
			} catch (err) {
				createGlobalError(err);
			}
		})();
		return () => {
			clearAppContextNotLocalStorage();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const showDiscountValue = () => {
		return (
			coupon?.value ?
				(<Row label="Total com desconto" data={(subtotal - coupon.value) + taxa} />)
				: null
		)
	}

	const showPayments = () => {
		return (
			info.key_mercadopago ?
				<PaymentBrick amount={subtotal} localValue={payment} localDisabled={!phoneRegex.test(phone)} localOnChange={(event) => setPayment(event.target.value)} paymentList={paymentList} onlineOnChange={setPayment} deliveryType={deliveryType} />
				: <select
					value={payment}
					disabled={!phoneRegex.test(phone)}
					onChange={(event) => setPayment(event.target.value)}
				>
					<option
						key={0}
						value={"SELECIONAR"}
					>
						SELECIONAR
					</option>
					{
						paymentList.length > 0 && paymentList.filter((e) => (e.descricao !== 'PIX ONLINE' && e.descricao !== 'CREDITO ONLINE')).map((item) => (
							<option
								key={item.id_forma_pagto}
								value={item.descricao}
								disabled={item.disponivel !== 'TODOS' ? item.disponivel === deliveryType ? false : true : false}
							>
								{item.descricao.toUpperCase()}
							</option>
						))
					}
				</select>
		)
	}

	if (globalError) {
		return (
			<div className="default-container">
				<Alert
					message={globalError}
					danger={true}
					button="Voltar"
					onClose={handleGoBack}
				/>
			</div>
		);
	}
	if (showLoading || !info) {
		return (
			<div className="default-container">
				<Loading />
			</div>
		);
	}
	if (sendClicked && !onlinePayID) {
		return (
			<div className="default-container">
				<Loading />
			</div>
		)
	}
	return (
		<div className="Cart">
			{showLoading && <Loading />}
			{openScannerQrCode && (
				<ScannerQrCode
					onAction={handleAction}
					header={header}
					numberComanda={ficha?.number}
					onClose={() => setOpenScannerQrCode(false)}
				/>
			)}
			{openGoogleMaps && (
				<GoogleMaps
					onClose={handleCloseGoogleMaps}
					header={header}
					googleKey={info.geolocalizacao_key}
					handleBackGoogleMaps={() => {
						setOpenModalAddAddress({ open: false, withAdress: false });
						setOpenGoogleMaps(false);
					}
					}
				/>
			)}
			{openModalAddAddress.open && (
				<ModalAddress
					onClose={() =>
						setOpenModalAddAddress({ open: false, withAdress: false })
					}
					header={header}
					color={info.cor_padrao}
					googleKey={info.geolocalizacao_key}
					withAdress={openModalAddAddress.withAdress}
				/>
			)}
			{orderContextError && (
				<Alert
					message={orderContextError}
					danger={true}
					button="Ok"
					onClose={clearOrderContextError}
				/>
			)}
			{showSucessAlert && (
				<Alert
					message="Seu pedido foi realizado com sucesso! Você pode acompanhar o status na próxima página"
					danger={false}
					button="Ok"
					onClose={handleSuccess}
				/>
			)}
			{isComanda && (
				<Alert
					message={message}
					danger={true}
					button="Ok"
					onClose={() => setIsComanda(false)}
				/>
			)}
			{isRestaurantClose && (
				<Alert
					message="No momento estamos fechados!"
					danger={true}
					button="Ok"
					onClose={handleGoBackInformacoes}
				/>
			)}
			{openModalPedidoEnvido && (
				<ModalPedidoEnviado
					color={info.cor_padrao}
					onClose={handleFinishCard}
				/>
			)}

			<HeaderDefault
				label={
					ficha
						? `${ficha.type.toLocaleUpperCase()} ${ficha.number}`
						: "FINALIZAR PEDIDO"
				}
				onClick={() => history.push(`/${header}/cart`)}
			/>
			<div className="container">
				{onlinePayID && (
					<PaymentStatus payment={onlinePayID} onClose={() => {
						setonlinePayID('');
						setSendClicked(false);
					}}
						onSuccess={() => {
							setonlinePayID('');
							handleAction(true);
						}}
					/>
				)}
				<div className="col finish">
					<div>
						{!ficha && (
							<div className="section">
								{showInformacoes && (
									<div className="container-buttonEntregaRetirada">
										<button
											onClick={() => handleClickDelivery("ENTREGA")}
											className={`btn ${deliveryType === "ENTREGA" ? "active" : ""
												}`}
										>
											ENTREGA
										</button>
										<button
											onClick={() => handleClickDelivery("RETIRADA")}
											className={`btn ${deliveryType === "RETIRADA" ? "active" : ""
												}`}
										>
											RETIRADA
										</button>
									</div>
								)}
								<div className="section-input">
									{!showInformacoes && (
										<>
											<Input
												label="Número de Telefone"
												type="text"
												disabled={false}
												value={phone}
												onChangeText={handlePhoneMask}
												onBlur={() => { }}
												placeholder="(xx) xxxxx-xxxx"
											>
												<FiPhone size={24} className="icon-input" />
											</Input>
											<div className="container-buttonShowInformacoes">
												<button
													className="btn finishCart"
													onClick={handlePhoneInputBlurEvent}
												>
													CONTINUAR
												</button>
											</div>
										</>
									)}

									{showInformacoes && (
										<>
											{phone !== "" && (
												<span className="span-number-finishcart">{phone}</span>
											)}
											<Input
												label="Seu Nome"
												type="text"
												disabled={!phoneRegex.test(phone)}
												value={name}
												onChangeText={setName}
												onBlur={() => { }}
											>
												<FiUser size={24} className="icon-input" />
											</Input>
										</>
									)}
								</div>
							</div>
						)}

						{!ficha && deliveryType === "RETIRADA" && showInformacoes && (
							<div className="section-input">
								<CartAddress
									color={info.cor_padrao}
									onPress={() => { }}
									isRetirada={true}
								>
									<span>Retirar em</span>
									<span>
										{info.endereco}, {info.numero}
									</span>
									<span>{info.bairro}</span>
								</CartAddress>
								<CartInfoDelivery color={info.cor_padrao}>
									<span>{timeNoDelivery} min</span>
									<span>Grátis</span>
								</CartInfoDelivery>
							</div>
						)}
						{!ficha &&
							deliveryType === "ENTREGA" &&
							subtotal >= info.valor_minimo_pedido &&
							showInformacoes && (
								<div className="section">
									<div className="section-input">
										{address === "" ? (
											<div
												className="cart-address notAddress"
												onClick={() =>
													setOpenModalAddAddress({
														open: true,
														withAdress: false,
													})
												}
											>
												<RiMapPin2Fill size={24} color="#58585a" />
												<span>
													Toque aqui para adicionar o endereço de entrega!
												</span>
											</div>
										) : (
											<>
												<CartAddress
													color={info.cor_padrao}
													onPress={() => setOpenGoogleMaps(true)}
													isRetirada={false}
												>
													<span>Entregar em</span>
													<span>
														{address}, {addressNumber}
													</span>
													<span>{neighborhood}</span>
												</CartAddress>
												<CartInfoDelivery color={info.cor_padrao}>
													<span>{neighborhood}</span>
													<span>
														{timeNoDelivery} - {timeDelivery} min
													</span>
													<span>{formatValue(taxa)}</span>
												</CartInfoDelivery>
											</>
										)}
									</div>
								</div>
							)}
						<div className="section-input">
							<div className="section-input-row">
								{(deliveryType === "ENTREGA" || deliveryType === "RETIRADA") && showInformacoes && (
									<div
										className={`Input ${!phoneRegex.test(phone) ? "disabled" : ""
											}`}
									>
										<label>Forma de pagamento:</label>
										{showPayments()}
									</div>
								)}
							</div>
							<div className="section-input-row">
								{payment === "DINHEIRO" &&
									showInformacoes &&
									(deliveryType === "ENTREGA" || deliveryType === "RETIRADA") && (
										<Input
											label="Troco para..."
											type="number"
											disabled={!phoneRegex.test(phone)}
											value={change}
											onChangeText={setChange}
											onBlur={handleChangeInputBlurEvent}
										>
											<FiDollarSign size={24} className="icon-input" />
										</Input>
									)}
							</div>
						</div>

						<div className="section">
							{ficha && info.smart_identificar_cliente === 1 && (
								<>
									<ListHeader
										label1="Informe seu fone e nome para se identificar"
										details={true}
										finishCart={true}
									/>
									<div className="section-valorFinishCart">
										<>
											<Input
												label="Número de Telefone"
												type="text"
												disabled={false}
												value={phone}
												onChangeText={handlePhoneMask}
												onBlur={handlePhoneInputBlurEvent}
												placeholder="(xx) xxxxx-xxxx"
											>
												<FiPhone size={24} className="icon-input" />
											</Input>
											<Input
												label="Seu Nome"
												type="text"
												disabled={!phoneRegex.test(phone)}
												value={name}
												onChangeText={setName}
												onBlur={() => null}
											>
												<FiUser size={24} className="icon-input" />
											</Input>
										</>
									</div>
								</>
							)}
							{ficha && ficha.type === "Comanda" && (
								<>
									<ListHeader
										label1="Informe a mesa que voce está localizado"
										details={true}
										finishCart={true}
									/>
									<div style={{ paddingInline: 5 }}>
										<Input
											label="Mesa"
											type="number"
											disabled={
												info.smart_identificar_cliente
													? !phoneRegex.test(phone)
													: false
											}
											value={table}
											onChangeText={(text) => handleSetMesa(text)}
											onBlur={() => null}
										>
											<FiUser size={24} className="icon-input" />
										</Input>
									</div>
								</>
							)}
						</div>
					</div>
					{uniqueCouponWarning && (
						<div className="container-desconto"> <span style={{ color: '#b8232a' }}>{uniqueCouponWarning}</span> </div>
					)}
				</div>

				<div className="container-button-finishcard">
					{!ficha ? (
						<Row label="TOTAL COM A ENTREGA" data={subtotal + taxa} />
					) : (
						<Row label="Total" data={subtotal + taxa} />
					)}
					{showDiscountValue()}
				</div>
				<div className="floating-container">
					<button
						disabled={sendClicked || (!showInformacoes && ficha?.type !== "Mesa" && table === "") || ((!name || !phone) && info.smart_identificar_cliente > 0) || (ficha?.type === "Comanda" && info.smart_identificar_cliente === 0 && table === "")}
						className={`btn btn-primary ${(!showInformacoes && ficha?.type !== "Mesa" && table === "") || ((!name || !phone) && info.smart_identificar_cliente > 0) || (ficha?.type === "Comanda" && info.smart_identificar_cliente === 0 && table === "")
							? "opacity"
							: ""
							}`}
						onClick={
							!ficha
								? () => handleAction()
								: info.escanear_qr_para_confirmar > 0
									? () => setOpenScannerQrCode(true)
									: () => handleAction()
						}
					>
						ENVIAR PEDIDO
					</button>
				</div>
			</div>
		</div>
	);
};

export default FinishCart;
