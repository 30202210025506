import React, { useState, createContext, useContext, useCallback } from "react";
import server from "../services/newServer";
import axios from "axios";

import ReactPixel from "react-facebook-pixel";

import {
	Order,
	Cart,
	Combo,
	ProductDetails,
	Observation,
	Question,
	PixelEvent,
} from "../utils/types";
import { justNumbers, decryptText, incryptText, calculeTotal, generateHash } from "../utils/functions";
import AppContext, { AppContextType } from "./AppContext";
export interface OrderContextType {
	cart: Array<Cart>;
	combos: Array<Combo>;
	orderContextError: any;
	preparationTime: number;
	table: string;
	address: string;
	addressNumber: string;
	complement: string;
	city: string;
	neighborhood: string;
	taxa: number;
	idEntrega: number;
	position: any;
	setTable: any;
	coupon: any;
	cardNumber: string;
	cardCode: string;
	cardExpiration: string;
	cardHolderName: string;
	cardCpf: string;
	paymentMethodId: string;

	getPrepTime(): number;
	getWaitTime(): number;

	handleChangeAddressComplement(text: string): void;
	handleChangeAddressNumber(text: string): void;
	handleChangeNeighborhood(text: string): void;
	handleChangeCity(text: string): void;
	handleChangeAddress(text: string): void;
	handleChangeTaxa(value: number): void;
	handleChangeidEntrega(value: number): void;
	handleAdressConfirm(
		infoAddress: google.maps.GeocoderAddressComponent[]
	): void;

	addProductToCart(
		product: ProductDetails | any,
		quant: number,
		compTotal: number,
		obs: string,
		observations: Array<Observation>,
		questions: Array<Question>,
		productByQuestion?: any,
		productIncorporado?: any
	): void;

	addProductToCombo(
		product: ProductDetails | any,
		itens: Array<Cart>,
		quant: number,
		subtotal: number,
		compTotal: number,
		obs: string,
		tipo: any,
	): Promise<void>;

	addTableCommand(mesa: string);

	removeProductFromCart(index: number): void;

	removeProductFromCombo(index: number): void;
	changeQuantity(index: number, quant: number, tipo?: string): void;
	handleGetDelivery(header: string, local: any): Promise<any>;
	create(header: string, params: Order, teste: number | null): Promise<any>;
	handleAttCartScreen(): void;

	createOrderContextError(err: any): void;
	clearOrderContextError(): void;
	clearOrderContext(): void;
	handleClearSession(): void;
	handleClearStateAdress(): void;

	setWaitTime(time: number): void;
	setDeliveryTime(time: number): void;
	deliveryTime: any;

	initFacebookPixel(header: string, codigo_pixel: string): void;
	acceptFacebookPixel(): void;
	trackPixelEvent(event: PixelEvent): void;
	showAcceptCookiesButton(): boolean;
	addCoupon(c: any): any;
	updateCardInfo(type: string, info: string);
	clearCardInfo(): any;
}

const OrderContext: React.Context<OrderContextType | any> = createContext({});

export const OrderProvider: React.FC = ({ children }) => {
	const { client, clearClient, info } = useContext<AppContextType>(AppContext);
	const [cart, setCart] = useState<Array<Cart>>([]);
	const [combos, setCombos] = useState<Array<Combo>>([]);
	const [orderContextError, setOrderContextError] = useState<any>(null);
	const [waitTime, setWaitTime] = useState(0);
	const [preparationTime, setPreparationTime] = useState<number>(0);

	const [deliveryTime, setDeliveryTime] = useState<number>(0);

	const [table, setTable] = useState<string>("");

	const [pixelConsent, setPixelConsent] = useState<boolean>(false);
	const [pixelInitiated, setPixelInitiated] = useState<boolean>(false);

	const [address, setAddress] = useState<string>(
		client?.endereco || ""
	); /* Endereço / Logradouro */

	const [addressNumber, setAddressNumber] = useState<string>(
		client?.numero?.toString() || ""
	); /* Número */

	const [complement, setComplement] = useState<string>(
		client && client.complemento ? client.complemento : ""
	); /* Complemento */

	/* Informações do pedido */
	const [city, setCity] = useState<string>("none"); /* Cidade escolhida */

	const [neighborhood, setNeighborhood] =
		useState<string>("none"); /* Bairro escolhido */

	const [taxa, setTaxa] = useState<number>(0);

	const [position, setPosition] = useState<any>();

	const [idEntrega, setIdEntrega] = useState<number>(0); /* ID da entrega */

	const [coupon, setCoupon] = useState<any>();

	const [cardNumber, setCardNumber] = useState<string>('');
	const [cardExpiration, setCardExpiration] = useState<string>('');
	const [cardCode, setCardCode] = useState<string>('');
	const [cardCpf, setCardCpf] = useState<string>('');
	const [cardHolderName, setCardHolderName] = useState<string>('');
	const [paymentMethodId, setPaymentMethodId] = useState<string>('');

	const addProductToCart = (
		product: ProductDetails | any,
		quant: number,
		compTotal: number,
		obs: string,
		observations: Array<Observation>,
		questions: Array<Question>,
		productByQuestion?: any,
		productIncorporado?: any
	) => {
		if (pixelInitiated) {
			const pixelData = {
				content_ids: product.id,
				content_type: "product",
				value: product.valor,
				currency: "BRL",
				content_name: product.nome,
			};
			trackPixelEvent("AddToCart", pixelData);
		}
		//Remove o item se estiver editando
		const editando = sessionStorage.getItem("@CHEF:editando");
		if (editando) {
			const editandoDecrypt = decryptText(editando)
			const editandoObj = JSON.parse(editandoDecrypt);
			if (editandoObj.answersIndexes) {
				editandoObj.answersIndexes.forEach(element => {
					cart?.splice(editandoObj.answersIndexes[0], 1); //Remove o item da resposta do carrinho
				});
			}
			cart?.splice(editandoObj.item, 1)
		};
		let temp = [...cart];

		product.quant = quant;
		product.valor = calculeTotal(product, quant, compTotal) / quant;
		product.total = quant * product.valor;
		product.comp_total = compTotal;
		product.custom_obs = obs;
		product.observacoes = observations;
		product.questions = questions;

		if (product.tempo_preparo > preparationTime) {
			const prepTime = product.tempo_preparo;
			setPreparationTime(prepTime);
		}
		if (productIncorporado) {
			product.adicionais = [...product.adicionais, ...productIncorporado];
		}
		temp.push(product);
		if (productByQuestion) temp = [...temp, ...productByQuestion];
		sessionStorage.setItem("@CHEF:cart", incryptText(JSON.stringify(temp)));
		setCart(temp);
	};

	function getPrepTime(): number {
		return preparationTime;
	}

	function getWaitTime(): number {
		return waitTime;
	}

	const handleChangeAddressNumber = (text: string) => {
		if (text === "S/N") {
			setAddressNumber(text);
			return;
		}
		let formatText = justNumbers(text);
		setAddressNumber(String(formatText));
	};

	const handleChangeAddress = (text: string) => {
		setAddress(text);
	};

	const handleChangeAddressComplement = (text: string) => {
		setComplement(text);
	};

	const handleChangeCity = (text: string) => {
		setCity(text);
	};

	const handleChangeNeighborhood = (text: string) => {
		setNeighborhood(text);
	};

	const handleChangeTaxa = (value: number) => {
		setTaxa(value);
	};
	const handleChangeidEntrega = (value: number) => {
		setIdEntrega(value);
	};

	const updateCardInfo = (type: string, info: string) => {
		switch (type) {
			case 'NUMBER':
				setCardNumber(info);
				break;
			case 'EXPIRATION':
				setCardExpiration(info);
				break;
			case 'CODE':
				setCardCode(info);
				break;
			case 'HOLDER':
				setCardHolderName(info);
				break;
			case 'CPF':
				setCardCpf(info);
				break;
			case 'METHOD':
				setPaymentMethodId(info);
				break;
			default:
				break;
		}
	}

	const clearCardInfo = () => {
		setCardNumber('');
		setCardExpiration('');
		setCardCode('');
		setCardHolderName('');
		setCardCpf('');
		setPaymentMethodId('');
	};

	const handleAdressConfirm = useCallback(
		(adress: google.maps.GeocoderAddressComponent[]) => {
			//Seta o bairro para none caso o enderesso n possua rua ativa o campo de bairro
			handleChangeNeighborhood("none");
			adress.forEach((element, index) => {
				const type = element.types[0];
				switch (type) {
					case "street_number":
						handleChangeAddressNumber(element.long_name);
						break;
					case "route":
						handleChangeAddress(element.long_name);
						break;
					case "political":
						handleChangeNeighborhood(element.long_name);
						break;
					case "sublocality_level_1":
						handleChangeNeighborhood(element.long_name);
						break;
					case "administrative_area_level_2":
						handleChangeCity(element.long_name);
						break;
				}
			});
		},
		[]
	);

	const addCoupon = (c: any) => {
		setCoupon(c);
	}

	const addProductToCombo = async (
		product: ProductDetails | any,
		itens: Array<Cart>,
		quant: number,
		subtotal: number,
		compTotal: number,
		obs: string,
		tipo: any,
	) => {
		if (pixelInitiated) {
			const pixelData = {
				content_ids: product.id,
				content_type: "product",
				value: product.valor,
				currency: "BRL",
				content_name: product.nome,
			};
			trackPixelEvent("AddToCart", pixelData);
		}

		let temp = [...combos];

		product.quant = quant;
		product.valor = subtotal + compTotal;
		product.total = quant * (subtotal + compTotal);
		product.comp_total = compTotal;
		product.itens = itens;
		product.custom_obs = obs;

		delete product.adicionais;
		delete product.opcionais;

		const productWithTipo = { ...product, tipo: tipo }
		temp.push(productWithTipo);
		//Remove o item se estiver editando
		const editando = sessionStorage.getItem("@CHEF:editando");
		const editandoPizza = sessionStorage.getItem("@CHEF:editandoPizza");
		if (editando) {
			const editandoDecrypt = decryptText(editando)
			const editandoObj = JSON.parse(editandoDecrypt);
			temp?.splice(editandoObj.item, 1);
		} else if (editandoPizza) {
			const editandoDecrypt = decryptText(editandoPizza)
			const editandoObj = JSON.parse(editandoDecrypt);
			temp?.splice(editandoObj.item, 1);
		}
		sessionStorage.setItem("@CHEF:combos", incryptText(JSON.stringify(temp)));
		setCombos(temp);
	};

	const removeProductFromCart = (index: number) => {
		const local = [...cart];
		local?.splice(index, 1);
		sessionStorage.setItem("@CHEF:cart", incryptText(JSON.stringify(local)));
		setCart(local);
	};

	const removeProductFromCombo = (index: number) => {
		const local = [...combos];
		local.splice(index, 1);
		sessionStorage.setItem("@CHEF:combos", incryptText(JSON.stringify(local)));
		setCombos(local);
	};

	const changeQuantity = (index: number, quant: number, tipo?: string) => {
		if (tipo === "PERSONALIZADO") {
			const local = [...combos];
			local[index].quant = quant;
			local[index].total = local[index].valor * quant;
			sessionStorage.setItem("@CHEF:combos", incryptText(JSON.stringify(local)));
			setCombos(local);
		} else {
			const local = [...cart];
			const product = local[index];
			local[index].quant = quant;
			// Modifica o valor para o preco voamenu, para poder re-calcular
			product.valor = product?.preco_voamenu > 0 ? product.preco_voamenu : product.valor;
			local[index].valor = calculeTotal(product, quant) / quant;
			local[index].total = calculeTotal(product, quant);
			sessionStorage.setItem("@CHEF:cart", incryptText(JSON.stringify(local)));
			setCart(local);
		}
	}

	const addTableCommand = (table: string) => {
		setTable(table);
	};

	const getPixelData = () => {
		const contents = [];
		let total = 0;
		const products = [];
		const contentIds = [];
		if (cart?.length > 0) {
			for (const cartItens of cart) {
				contents.push({
					id: cartItens.id,
					quantity: cartItens.quant,
				});
				products.push(cartItens.nome);
				contentIds.push(cartItens.id);
				total += cartItens.total;
			}
		}

		if (combos?.length > 0) {
			for (const comboItens of combos) {
				contents.push({
					id: comboItens["id"],
					quantity: comboItens.quant,
				});
				products.push(comboItens.nome);
				contentIds.push(comboItens["id"]);
				total += comboItens.total;
			}
		}

		const data = {
			num_items: cart?.length + combos?.length,
			contents: contents,
			content_type: "products",
			content_name: products,
			content_ids: contentIds,
			value: total,
			currency: "BRL",
		};

		return data;
	};

	const handleGetDelivery = async (header: string, local: any) => {
		try {
			const resp = await server.post("/geolocation/delivery-fee", local, {
				headers: { id: header },
			});
			if (resp.data) {
				setPosition(local);
				//VERIFICAR ENTREGA GRATIS
				const data = new Date().getDay();
				switch (data) {
					case 0:
						if (resp.data.domingo > 0)
							setTaxa(0);
						else
							setTaxa(resp.data.valor);
						break;
					case 1:
						if (resp.data.segunda > 0)
							setTaxa(0);
						else
							setTaxa(resp.data.valor);
						break;
					case 2:
						if (resp.data.terca > 0)
							setTaxa(0);
						else
							setTaxa(resp.data.valor);
						break;
					case 3:
						if (resp.data.quarta > 0)
							setTaxa(0);
						else
							setTaxa(resp.data.valor);
						break;
					case 4:
						if (resp.data.quinta > 0)
							setTaxa(0);
						else
							setTaxa(resp.data.valor);
						break;
					case 5:
						if (resp.data.sexta > 0)
							setTaxa(0);
						else
							setTaxa(resp.data.valor);
						break;
					case 6:
						if (resp.data.sabado > 0)
							setTaxa(0);
						else
							setTaxa(resp.data.valor);
						break;
					default:
						break;
				}
				//setTaxa(resp.data.valor);
				setIdEntrega(resp.data.id_entrega);
			}
			return resp.data;
		} catch (err) {
			clearClient();
			createOrderContextError("Não entregamos nessa localização");
		}
	};

	const create = async (header: string, params: Order, newWaitTime: number) => {
		try {
			if (pixelInitiated) {
				const data = getPixelData();
				trackPixelEvent("Purchase", data);
			}
			params.time = newWaitTime + preparationTime + Number(deliveryTime);
			params.hash_venda = generateHash();
			const resp = await server.post("/orders", params, {
				headers: { id: header },
			});
			clearOrderContext();
			return resp.data;
		} catch (err) {
			await axios.post("https://51bliwps7e.execute-api.sa-east-1.amazonaws.com/dev/Enviar-email-suporte", {
				title: `[EMAIL REPORT]: Erro ao enviar venda - ${header}`,
				app: "Voamenu",
				token: header,
				version: "production",
				message: `${JSON.stringify(err?.response)}`,
				fantasia: header,
				cnpj: '',
				email: info?.email,
				fone: info?.fone,
				razao: header
			})
			createOrderContextError(err);
		}
	};

	const createOrderContextError = (err: any) => {
		try {
			if (typeof err == "string") {
				setOrderContextError(err);
			} else {
				if (err.response.data) {
					setOrderContextError(
						err.response.data.message || err.response.data.type
					);
				} else {
					setOrderContextError(err.message || err.error || err.err);
				}
			}
		} catch (err) {
			setOrderContextError(
				"Algum processo do site não foi finalizado ou não foi possível realiza-lo. Tente novamente!"
			);
		}
	};

	const clearOrderContextError = () => setOrderContextError(null);
	const clearOrderContext = () => {
		setCart([]);
		setCombos([]);
		setCoupon('');
		setOrderContextError(null);
		// sessionStorage.clear();
	};

	const initFacebookPixel = (header: string, codigo_pixel: string) => {
		if (codigo_pixel !== "" && !pixelInitiated) {
			// const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
			const options = {
				autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
				debug: true, // enable logs
			};
			ReactPixel.init(codigo_pixel, null, options);
			// ReactPixel.init(`3093652464238777`, null, options);
			// ReactPixel.revokeConsent();
			setPixelInitiated(true);
			ReactPixel.pageView();
		}
	};

	const showAcceptCookiesButton = (): boolean => {
		return pixelInitiated && !pixelConsent;
	};

	const acceptFacebookPixel = () => {
		ReactPixel.grantConsent();
		setPixelConsent(true);
	};

	const trackPixelEvent = (event: PixelEvent, data: any = null) => {
		getPixelData();
		if (event === "InitiateCheckout") {
			data = {
				...getPixelData(),
			};
		}
		ReactPixel.track(event, data);
	};

	const handleAttCartScreen = () => {
		const localCart = sessionStorage.getItem("@CHEF:cart");
		const localCombos = sessionStorage.getItem("@CHEF:combos");
		if (localCart) setCart(JSON.parse(decryptText(localCart)));
		if (localCombos) setCombos(JSON.parse(decryptText(localCombos)));
	};

	const handleClearStateAdress = () => {
		setAddress("");
		setAddressNumber("");
		setCity("");
		setComplement("");
		setNeighborhood("");
	};

	const handleClearSession = () => {
		sessionStorage.clear();
	};

	return (
		<OrderContext.Provider
			value={{
				cart,
				combos,
				table,
				address,
				addressNumber,
				complement,
				city,
				neighborhood,
				taxa,
				idEntrega,
				position,
				setTable,
				orderContextError,
				coupon,
				cardNumber,
				cardCode,
				cardExpiration,
				cardHolderName,
				cardCpf,
				paymentMethodId,

				handleGetDelivery,
				handleChangeAddress,
				handleChangeAddressNumber,
				handleChangeAddressComplement,
				handleChangeNeighborhood,
				handleChangeCity,
				handleAdressConfirm,
				handleChangeTaxa,
				handleChangeidEntrega,
				addProductToCart,
				addProductToCombo,
				addTableCommand,
				removeProductFromCart,
				removeProductFromCombo,
				changeQuantity,
				create,
				handleClearStateAdress,
				handleAttCartScreen,
				addCoupon,

				createOrderContextError,
				clearOrderContextError,
				clearOrderContext,
				handleClearSession,

				setWaitTime,
				setDeliveryTime,
				deliveryTime,
				getPrepTime,
				getWaitTime,

				initFacebookPixel,
				acceptFacebookPixel,
				trackPixelEvent,
				showAcceptCookiesButton,
				updateCardInfo,
				clearCardInfo,
			}}
		>
			{children}
		</OrderContext.Provider>
	);
};

export default OrderContext;
